import React, { useState, useEffect } from "react"
import Seo from "../components/SEO"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Mailer from "../components/ContactMailer/Mailer"
import paths from "../constants/paths"

const Container = styled.div`
  position: relative;

  p {
    word-wrap: break-word;
  }

  > section {
    padding: 0;
    background: rgba(245, 244, 233, 1);
    z-index: 10;
    padding: 1rem;
  }

  h2 {
    margin-top: 0;
    padding-top: 1rem;
  }

  input, textarea {
    width: 100%;
  }

  @media (min-width: 480px) {
    input, textarea {
      width: auto;   
    }
    button {
      padding-left: 3rem;
      padding-right: 3rem;
    }

    p {
      text-align: left;
    }
  }
  
  @media (min-width: 768px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
    
    > section {
      margin: 0 auto;
      border-radius: 25px;
      width: 80vw;
      max-width: 1000px;
      text-align: center;  
    }
  }
`;

const BackgroundImage = styled.div`
  display: none;
  visibility: hidden;

  @media (min-width: 768px) {
    display: block;
    visibility: visible;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -1;

    .gatsby-image-wrapper, img {
      height: 100vh;
      width: 100vw;
    }
  }
`

const Contact = ({ location }) => {
	const [hasMounted, setHasMounted] = useState(false);
	const [width, setWidth] = useState(2000);
 
	useEffect(() => {
		const updateDimensions = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', updateDimensions);
		updateDimensions();
	})

	React.useEffect(() => {
		setHasMounted(true);
	}, []);

  return (
    <Container>
      <Seo title={paths.contact.title} location={location} />
      <BackgroundImage>
        <StaticImage alt="Hurricane Ridge Adventure Wedding Olympic National Park Elopement Photographer 4" src="../images/theme/mountains/Hurricane-Ridge-Adventure-Wedding-Olympic-National-Park-Elopement-Photographer-4.jpg" width={1440} />
      </BackgroundImage>
      <section>
        <h2>Contact Me</h2>
        
        <p><em>Thank you</em> so much for reaching out to me by filling out the form below. I can't wait to celebrate your wedding with you.</p>

        <Mailer />
      </section>
    </Container>
  )
}

export default Contact
