import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { CopyToClipboard } from 'react-copy-to-clipboard';

const CONTACT_EMAIL_ADDRESS = 'contact@bettyelainephotography.com';
const MAILER_URL = `https://yb8br66j1e.execute-api.us-west-2.amazonaws.com/prod/`;

const Container = styled.div`
  form {
    padding: 1rem;
  }
  &:data[data-emailsent] form {
    visibility: hidden;
    display: none;
  }
`;

const MailerStatusMessageContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  background: var(--brand-color-green);
  bottom: 1px;
  color: var(--brand-color-off-white);
  font-family: var(--header-font-families);
  text-align: center;
  padding-top: 3rem;

  h2 {
    margin-bottom: 0;
  }

  textarea {
    width: 100%;
    height: 250px;
  }

  p {
    text-align: left;
    padding-bottom: 1rem;
    max-width: 500px;
    margin: 0 auto;

    a {
      color: var(--brand-color-white);
      font-weight: bold;
    }
  }

  button {
    margin-top: 1rem;
  }

  @media (min-width: 768px) {
    textarea {
      max-width: 700px;
    }
  }
`

const BottomInstruction = styled.div`
  p {
    text-align: left;
  }
`

interface EmailFormData {
  [x: string]: any;
  name?: string
  spouseToBeName?: string
  email?: string
  referral?: string
  eventDate?: string
  venueName?: string
  whyMe?: string
  loveStory?: string
  threeFunThings?: string
}

const emailParamLabels = new Map([
  ['email', 'Email'],
  ['name', 'Name'],
  ['eventDate', 'Event Date'],
  ['spouseToBeName', 'Spouse To Be'],
  ['referral', 'Referral'],
  ['venueName', 'Wedding Location/Venue'],
  ['whyMe', 'Why Me?'],
  ['loveStory', 'Love Story'],
  ['threeFunThings', 'Three Fun Things']
]);

// For testing the mailer states
// const defaultEmailFormData: EmailFormData = {
//   name: "Matt",
//   spouseToBeName: "Indie",
//   email: "matt@example.com",
//   referral: "Google",
//   eventDate: "09/12/2022",
//   venueName: "The barn house",
//   whyMe: "asdf asdf asdf asdf asdf asdf asdf asdf adsf asdf asdf asdf asdf asdf asdf asdf asdf asdf asdf asdf asdf asdf asdf adsf asdf asdf asdf asdf asdf asdf",
//   loveStory: "asdf asdf asdf asdf asdf asdf asdf asdf adsf asdf asdf asdf asdf asdf asdf asdf asdf asdf asdf asdf asdf asdf asdf adsf asdf asdf asdf asdf asdf asdf",
//   threeFunThings: "asdf asdf asdf asdf asdf asdf asdf asdf adsf asdf asdf asdf asdf asdf asdf asdf asdf asdf asdf asdf asdf asdf asdf adsf asdf asdf asdf asdf asdf asdf asdf asdf asdf asdf asdf asdf asdf asdf adsf asdf asdf asdf asdf asdf asdf"
// }

const defaultEmailFormData: EmailFormData = {}

const Mailer = () => {
  const [formSubmitted, submitForm] = useState(false);
  const [emailDidSend, setEmailDidSend] = useState(false);
  const [emailFailedToSend, setEmailFailedToSend] = useState(false);
  const [emailFormData, setEmailFormData] = useState(defaultEmailFormData);
  const [successMessageDidClose, setSuccessMessageDidClose] = useState(false);
  const [failureMessageDidClose, setFailureMessageDidClose] = useState(false);
  const [emailTextCopied, setEmailTextCopied] = useState(false);

  const handleSubmit = function (event) {
    event.preventDefault();
    submitForm(true);
  }

  const handleChange = function (event) {
    console.log(emailFormData);
    const newFormData = { ...emailFormData }
    newFormData[event.target.name] = event.target.value
    setEmailFormData(newFormData)
  }

  if (formSubmitted && !emailDidSend) {
    fetch(MAILER_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(emailFormData) // body data type must match "Content-Type" header
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
      .then(data => {
        setEmailDidSend(true);
      })
      .catch((error) => {
        console.error('Error:', error);
        setEmailFailedToSend(true);
      });
  }

  const SuccessMessage = () => {
    if (!emailDidSend || successMessageDidClose) {
      return null;
    }
    const containerRef = useRef()

    useEffect(() => {
      containerRef.current.scrollIntoView({ behavior: 'smooth' })
    });

    return (
      <MailerStatusMessageContainer className="mailerSuccessMessage" ref={containerRef}>
        <h2>Email Sent!</h2>
        <p className="text-center">
          <StaticImage src="../../images/illustrations/Canoe-color-large.png" width={200} alt="Canoeing in North Cascades" />
        </p>
        <p>Thank you for your interest in Photography by Betty Elaine! Please allow 24-48 hours for a response. I am excited to connect and hear your story. Wishing you a beautiful day!</p>
        <p className="text-center">
          <StaticImage src="../../images/logos/Alt-Logo-3-cream-1000px.png" width={300} alt="Photography by Betty Elaine Logo" />
        </p>
        <p className="text-center">
          <button type="submit" className="pure-button pure-button-primary" onClick={() => setSuccessMessageDidClose(true)}>Close</button>
        </p>
      </MailerStatusMessageContainer>
    )
  }

  const FailureMessage = () => {
    if (!emailFailedToSend || failureMessageDidClose) {
      return null;
    }
    const containerRef = useRef()
    
    let manualEmailText = ''
    for (let key in emailFormData) {
      manualEmailText += `${emailParamLabels.get(key)}: ${emailFormData[key]}\r\n`
    }

    const mailerSubject = `Contact form for ${emailFormData.name}`
    const mailerBody = `${manualEmailText}\n\nFYI, your mailer form failed, so I'm sending this to you myself.`
    const mailtoLink = `mailto:${CONTACT_EMAIL_ADDRESS}?subject=${encodeURIComponent(mailerSubject)}&body=${encodeURIComponent(mailerBody)}`
    
    useEffect(() => {
      containerRef.current.scrollIntoView({ behavior: 'smooth' })
    });

    return (
      <MailerStatusMessageContainer className="mailerFailureMessage" ref={containerRef}>
        <h2>An error occurred!</h2>
        <p className="text-center">
          <StaticImage src="../../images/illustrations/Goldfinch-color-large.png" width={200} alt="Illustrated Gold Finch" />
        </p>
        <p>
          I'm so sorry, something went wrong and we weren't able to send your information.
        </p>
        <p>
          Please email the contents below (
          <CopyToClipboard text={mailerBody}
            onCopy={() => setEmailTextCopied(true)}>
            <a href="#">click to copy <i className="fas fa-clipboard"></i></a>
          </CopyToClipboard>) to me directly at: <a href={mailtoLink}>contact@bettyelainephotography.com</a>.
        </p>
        <p>
          <form id="contactForm" className="pure-form pure-form-aligned">
            <fieldset>
              <div className="pure-control-group">
                <textarea>
                  {mailerBody}
                </textarea>
              </div>
            </fieldset>
          </form>
        </p>
        <p className="text-center">
          <StaticImage src="../../images/logos/Alt-Logo-3-cream-1000px.png" width={300} alt="Photography by Betty Elaine Logo" />
        </p>
        <p className="text-center">
          <button type="submit" className="pure-button full-touch-button" onClick={() => setFailureMessageDidClose(true)}>Close</button>
        </p>
      </MailerStatusMessageContainer>
    )
  }

  return (
    <Container data-emailsent={emailDidSend}>
      <SuccessMessage />
      <FailureMessage />
      <form id="contactForm" className="pure-form pure-form-aligned" onSubmit={handleSubmit}>
        <fieldset disabled={formSubmitted}>
          <div className="pure-control-group">
            <label htmlFor="name">Your Name / Pronouns</label>
            <input type="text" id="name" placeholder="Name (she/her)" name="name" required onChange={handleChange} value={emailFormData.name} />
          </div>
          <div className="pure-control-group">
            <label htmlFor="spouseToBeName">Spouse-to-be / Pronouns</label>
            <input type="text" id="spouseToBeName" placeholder="Name (she/her)" name="spouseToBeName" required onChange={handleChange} value={emailFormData.spouseToBeName} />
          </div>
          <div className="pure-control-group">
            <label htmlFor="email">Email Address</label>
            <input type="email" id="email" placeholder="Email Address" name="email" required onChange={handleChange} value={emailFormData.email} />
          </div>
          <div className="pure-control-group">
            <label htmlFor="referral">Who can I thank for the referral / How did you find me?</label>
            <input type="text" id="referral" placeholder="Referral" name="referral" required onChange={handleChange} value={emailFormData.referral} />
          </div>
          <div className="pure-control-group">
            <label htmlFor="eventDate">Wedding Date</label>
            <input type="date" id="eventDate" name="eventDate" required onChange={handleChange} value={emailFormData.eventDate} />
          </div>
          <div className="pure-control-group">
            <label htmlFor="venueName">Wedding Location/Venue</label>
            <input type="text" id="venueName" placeholder="Venue" name="venueName" onChange={handleChange} value={emailFormData.venueName} />
          </div>
          <div className="pure-control-group">
            <label htmlFor="whyMe">There are a lot of photographers out there! What about my work do you like, and why me?</label>
            <textarea name="whyMe" id="whyMe" onChange={handleChange} value={emailFormData.whyMe}></textarea>
          </div>
          <div className="pure-control-group">
            <label htmlFor="loveStory">Tell me about yourselves and your love story!</label>
            <textarea id="loveStory" name="loveStory" onChange={handleChange} value={emailFormData.loveStory}></textarea>
          </div>
          <div className="pure-control-group">
            <label htmlFor="threeFunThings">Three fun things I should know about you and your fiance:</label>
            <textarea id="threeFunThings" name="threeFunThings" onChange={handleChange} value={emailFormData.threeFunThings}></textarea>
          </div>
          <div className="pure-controls">
            <button type="submit" className="pure-button pure-button-primary full-touch-button">Submit</button>
          </div>
        </fieldset>
      </form>

      <BottomInstruction>
        <p>Please fill out the contact form above, or email me directly at: <a href="mailto:contact@bettyelainephotography.com">contact@bettyelainephotography.com</a>.</p>
      </BottomInstruction>
    </Container>
  )
}

export default Mailer
